:root {
  --header-background-color: var(--brown-sugar);
}

header {
  position: sticky;
  top: 0rem;
  z-index: 100;

  background-color: var(--header-background-color);

  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: 8rem auto;

  -webkit-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);

  .header-introduction {
    display: none;
    grid-column: 1/3;
    padding: 0.5rem;

    p {
      margin: 0rem;
    }
  }

  .header-address {
    display: flex;
    flex-direction: column;

    margin-bottom: 1rem;
    @media (min-width: 576px) {
      grid-column: 2;
      margin-bottom: 0rem;
      margin-right: 2rem;
    }
    grid-column: 1/3;

    address {
      margin: auto;

      display: flex;
      flex-direction: column;

      a {
        margin-bottom: 0.5rem;
        color: white;
        fill: white;
        text-decoration: none;

        &:last-of-type {
          margin-bottom: 0rem;
        }
      }
    }
  }

  .header-logo {
    background: inherit;
    display: flex;

    picture {
      display: contents;
      max-height: 8rem;
    }

    img {
      position: relative;
      height: 6rem;
      width: auto;
      box-sizing: border-box;
      margin: auto;

      @media (min-width: 576px) {
        margin-left: 1rem;
      }
    }
  }

  .header-nav {
    display: none;

    grid-column: 1/3;
    border-top: solid black 0.1rem;

    ul {
      list-style: none;
      padding-left: 0rem;
      margin: 0rem;

      display: grid;
      grid-auto-flow: dense;
      grid-template-columns: repeat(auto-fill, minmax(5rem, 1fr));

      a {
        flex: auto;
        text-align: center;

        display: flex;
        height: 2.5rem;

        &:hover {
          background-color: aqua;
        }

        li {
          margin: auto;
        }
      }
    }
  }
}
.headroom {
  will-change: transform;
  transition: transform 200ms linear;
}
.headroom--pinned {
  transform: translateY(0%);
}
.headroom--unpinned {
  transform: translateY(-110%);
}
