footer {
  display: flex;
  flex-direction: column;
  margin: 1rem;
  padding-bottom: 1rem;
  margin-bottom: 0rem;

  p {
    margin: auto;
    text-align: center;

    color: white;
  }
}
