@import url("npm:normalize.css/normalize.css");
@import url("../google-fonts/fonts.css");
@import url("./colors.scss");
@import url("./header.scss");
@import url("./footer.scss");
@import url("./gallery.scss");

:root {
  --gallery-picture-width: 9rem;

  @media (min-width: 768px) {
    --gallery-picture-width: 12rem;
  }
}

body {
  font-family: "Open Sans", sans-serif;
  min-height: 100vh;

  min-width: 250px;
  max-width: 75rem;
  margin: auto;

  background-color: var(--dark-lava);

  -webkit-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
}

main {
  background-color: var(--almond);
  min-height: 50vh;
  padding: 1rem;
  overflow: auto;

  section {
    background-color: var(--burlywood);
    border-radius: 1rem;

    margin-bottom: 3rem;

    h1 {
      font-family: "Caveat", cursive;
      position: sticky;
      top: 0;
      background-color: var(--brown-sugar);
      padding: 1rem;
      border-radius: 1rem 1rem 0rem 0rem;
      border-bottom: 0.1rem solid white;
      color: white;
    }
  }
}

.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.125em;
}
