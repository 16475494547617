@import url("npm:photoswipe/dist/photoswipe.css");

.gallery {
  margin: auto;
  line-height: 0rem;
  max-width: 100%;

  column-count: auto;
  text-align: center;

  picture {
    display: contents;
  }

  img {
    width: var(--gallery-picture-width, 8rem);
    margin-bottom: 0.5rem;
    margin-left: 0.25rem;
    margin-right: 0.25rem;
    aspect-ratio: 3/4;
    object-fit: cover;

    border-style: solid;
    border-width: 0.1rem;
    border-color: white;
    border-radius: 1rem;
  }

  img:hover {
    outline: #621708 solid;
  }
}
