:root {
  /* CSS HEX */
  --almond: #e0cfbaff;
  --baby-powder: #fffbf7ff;
  --brown-sugar: #9c6644ff;
  --burlywood: #ddb892ff;
  --cafe-au-lait: #b08968ff;
  --coffee: #7f5539ff;
  --dark-lava: #463f3aff;
  --deep-champagne: #f3d38dff;
  --desert-sand: #e6ccb2ff;
  --gold-fusion: #857148ff;
  --isabelline: #f4f3eeff;
  --jasmine: #fcd786ff;
  --light-french-beige: #d1b16bff;
  --light-orange: #f5d7b5ff;
  --linen: #f2e9dfff;
  --melon: #e0afa0ff;
  --middle-grey: #8a817cff;
  --pale-silver: #bcb8b1ff;
  --tan: #cfab82ff;
  --wheat: #e8cf9bff;

  /* CSS HSL */
  --almond: hsla(29, 41%, 88%, 1);
  --brown-sugar: hsla(23, 39%, 44%, 1);
  --burlywood: hsla(30, 52%, 72%, 1);
  --cafe-au-lait: hsla(27, 31%, 55%, 1);
  --coffee: hsla(24, 38%, 36%, 1);
  --dark-lava: hsla(25, 9%, 25%, 1);
  --deep-champagne: hsla(41, 81%, 75%, 1);
  --desert-sand: hsla(30, 51%, 80%, 1);
  --gold-fusion: hsla(40, 30%, 40%, 1);
  --isabelline: hsla(50, 21%, 95%, 1);
  --jasmine: hsla(41, 95%, 76%, 1);
  --light-french-beige: hsla(41, 53%, 62%, 1);
  --melon: hsla(14, 51%, 75%, 1);
  --middle-grey: hsla(21, 6%, 51%, 1);
  --pale-silver: hsla(38, 8%, 72%, 1);
  --wheat: hsla(41, 63%, 76%, 1);
}
