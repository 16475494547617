html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

main {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  background-color: #0000;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  max-width: 100%;
  white-space: normal;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template, [hidden] {
  display: none;
}

@font-face {
  font-family: Caveat;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("Caveat-400-cyrillic-ext1.25aa65d5.woff2") format("woff2");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Caveat;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("Caveat-400-cyrillic2.46fbaa57.woff2") format("woff2");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Caveat;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("Caveat-400-latin-ext3.58a53241.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Caveat;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("Caveat-400-latin4.4fd08def.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
  src: url("Open_Sans-400-cyrillic-ext5.5a91320c.woff2") format("woff2");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
  src: url("Open_Sans-400-cyrillic6.09174fa0.woff2") format("woff2");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
  src: url("Open_Sans-400-greek-ext7.2d040ff0.woff2") format("woff2");
  unicode-range: U+1F??;
}

@font-face {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
  src: url("Open_Sans-400-greek8.dc2ae4bd.woff2") format("woff2");
  unicode-range: U+370-3FF;
}

@font-face {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
  src: url("Open_Sans-400-hebrew9.942ca237.woff2") format("woff2");
  unicode-range: U+590-5FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}

@font-face {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
  src: url("Open_Sans-400-vietnamese10.979797dd.woff2") format("woff2");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
  src: url("Open_Sans-400-latin-ext11.b040be2e.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
  src: url("Open_Sans-400-latin12.7cf38e2c.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

:root {
  --almond: #e0cfba;
  --baby-powder: #fffbf7;
  --brown-sugar: #9c6644;
  --burlywood: #ddb892;
  --cafe-au-lait: #b08968;
  --coffee: #7f5539;
  --dark-lava: #463f3a;
  --deep-champagne: #f3d38d;
  --desert-sand: #e6ccb2;
  --gold-fusion: #857148;
  --isabelline: #f4f3ee;
  --jasmine: #fcd786;
  --light-french-beige: #d1b16b;
  --light-orange: #f5d7b5;
  --linen: #f2e9df;
  --melon: #e0afa0;
  --middle-grey: #8a817c;
  --pale-silver: #bcb8b1;
  --tan: #cfab82;
  --wheat: #e8cf9b;
  --almond: #ede0d4;
  --brown-sugar: #9c6644;
  --burlywood: #ddb892;
  --cafe-au-lait: #b08969;
  --coffee: #7f5539;
  --dark-lava: #453f3a;
  --deep-champagne: #f3d28c;
  --desert-sand: #e6ccb2;
  --gold-fusion: #857047;
  --isabelline: #f5f4f0;
  --jasmine: #fcd788;
  --light-french-beige: #d1b16b;
  --melon: #e0ae9f;
  --middle-grey: #8a807b;
  --pale-silver: #bdb9b2;
  --wheat: #e8d09b;
}

:root {
  --header-background-color: var(--brown-sugar);
}

header {
  z-index: 100;
  background-color: var(--header-background-color);
  grid-template-rows: 8rem auto;
  grid-template-columns: 1fr auto;
  display: grid;
  position: sticky;
  top: 0;
  box-shadow: 0 5px 10px #00000080;
}

header .header-introduction {
  grid-column: 1 / 3;
  padding: .5rem;
  display: none;
}

header .header-introduction p {
  margin: 0;
}

header .header-address {
  flex-direction: column;
  grid-column: 1 / 3;
  margin-bottom: 1rem;
  display: flex;
}

@media (min-width: 576px) {
  header .header-address {
    grid-column: 2;
    margin-bottom: 0;
    margin-right: 2rem;
  }
}

header .header-address address {
  flex-direction: column;
  margin: auto;
  display: flex;
}

header .header-address address a {
  color: #fff;
  fill: #fff;
  margin-bottom: .5rem;
  text-decoration: none;
}

header .header-address address a:last-of-type {
  margin-bottom: 0;
}

header .header-logo {
  background: inherit;
  display: flex;
}

header .header-logo picture {
  max-height: 8rem;
  display: contents;
}

header .header-logo img {
  height: 6rem;
  width: auto;
  box-sizing: border-box;
  margin: auto;
  position: relative;
}

@media (min-width: 576px) {
  header .header-logo img {
    margin-left: 1rem;
  }
}

header .header-nav {
  border-top: .1rem solid #000;
  grid-column: 1 / 3;
  display: none;
}

header .header-nav ul {
  grid-auto-flow: dense;
  grid-template-columns: repeat(auto-fill, minmax(5rem, 1fr));
  margin: 0;
  padding-left: 0;
  list-style: none;
  display: grid;
}

header .header-nav ul a {
  text-align: center;
  height: 2.5rem;
  flex: auto;
  display: flex;
}

header .header-nav ul a:hover {
  background-color: #0ff;
}

header .header-nav ul a li {
  margin: auto;
}

.headroom {
  will-change: transform;
  transition: transform .2s linear;
}

.headroom--pinned {
  transform: translateY(0%);
}

.headroom--unpinned {
  transform: translateY(-110%);
}

footer {
  flex-direction: column;
  margin: 1rem 1rem 0;
  padding-bottom: 1rem;
  display: flex;
}

footer p {
  text-align: center;
  color: #fff;
  margin: auto;
}

.pswp {
  --pswp-bg: #000;
  --pswp-placeholder-bg: #222;
  --pswp-root-z-index: 100000;
  --pswp-preloader-color: #4f4f4f66;
  --pswp-preloader-color-secondary: #ffffffe6;
  --pswp-icon-color: #fff;
  --pswp-icon-color-secondary: #4f4f4f;
  --pswp-icon-stroke-color: #4f4f4f;
  --pswp-icon-stroke-width: 2px;
  --pswp-error-text-color: var(--pswp-icon-color);
  width: 100%;
  height: 100%;
  z-index: var(--pswp-root-z-index);
  touch-action: none;
  opacity: .003;
  contain: layout style size;
  -webkit-tap-highlight-color: #0000;
  outline: 0;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

.pswp:focus {
  outline: 0;
}

.pswp * {
  box-sizing: border-box;
}

.pswp img {
  max-width: none;
}

.pswp--open {
  display: block;
}

.pswp, .pswp__bg {
  will-change: opacity;
  transform: translateZ(0);
}

.pswp__bg {
  opacity: .005;
  background: var(--pswp-bg);
}

.pswp, .pswp__scroll-wrap {
  overflow: hidden;
}

.pswp__scroll-wrap, .pswp__bg, .pswp__container, .pswp__item, .pswp__content, .pswp__img, .pswp__zoom-wrap {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.pswp__img, .pswp__zoom-wrap {
  width: auto;
  height: auto;
}

.pswp--click-to-zoom.pswp--zoom-allowed .pswp__img {
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in;
}

.pswp--click-to-zoom.pswp--zoomed-in .pswp__img {
  cursor: move;
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: grab;
}

.pswp--click-to-zoom.pswp--zoomed-in .pswp__img:active {
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: grabbing;
}

.pswp--no-mouse-drag.pswp--zoomed-in .pswp__img, .pswp--no-mouse-drag.pswp--zoomed-in .pswp__img:active, .pswp__img {
  cursor: -webkit-zoom-out;
  cursor: -moz-zoom-out;
  cursor: zoom-out;
}

.pswp__container, .pswp__img, .pswp__button, .pswp__counter {
  -webkit-user-select: none;
  user-select: none;
}

.pswp__item {
  z-index: 1;
  overflow: hidden;
}

.pswp__hidden {
  display: none !important;
}

.pswp__content {
  pointer-events: none;
}

.pswp__content > * {
  pointer-events: auto;
}

.pswp__error-msg-container {
  display: grid;
}

.pswp__error-msg {
  color: var(--pswp-error-text-color);
  margin: auto;
  font-size: 1em;
  line-height: 1;
}

.pswp .pswp__hide-on-close {
  opacity: .005;
  will-change: opacity;
  transition: opacity var(--pswp-transition-duration) cubic-bezier(.4, 0, .22, 1);
  z-index: 10;
  pointer-events: none;
}

.pswp--ui-visible .pswp__hide-on-close {
  opacity: 1;
  pointer-events: auto;
}

.pswp__button {
  width: 50px;
  height: 60px;
  cursor: pointer;
  box-shadow: none;
  opacity: .85;
  -webkit-appearance: none;
  -webkit-touch-callout: none;
  background: none;
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: relative;
  overflow: hidden;
}

.pswp__button:hover, .pswp__button:active, .pswp__button:focus {
  box-shadow: none;
  opacity: 1;
  background: none;
  border: 0;
  padding: 0;
  transition: none;
}

.pswp__button:disabled {
  opacity: .3;
  cursor: auto;
}

.pswp__icn {
  fill: var(--pswp-icon-color);
  color: var(--pswp-icon-color-secondary);
  width: 32px;
  height: 32px;
  pointer-events: none;
  position: absolute;
  top: 14px;
  left: 9px;
  overflow: hidden;
}

.pswp__icn-shadow {
  stroke: var(--pswp-icon-stroke-color);
  stroke-width: var(--pswp-icon-stroke-width);
  fill: none;
}

.pswp__icn:focus {
  outline: 0;
}

div.pswp__img--placeholder, .pswp__img--with-bg {
  background: var(--pswp-placeholder-bg);
}

.pswp__top-bar {
  width: 100%;
  height: 60px;
  z-index: 10;
  flex-direction: row;
  justify-content: flex-end;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none !important;
}

.pswp__top-bar > * {
  pointer-events: auto;
  will-change: opacity;
}

.pswp__button--close {
  margin-right: 6px;
}

.pswp__button--arrow {
  width: 75px;
  height: 100px;
  margin-top: -50px;
  position: absolute;
  top: 50%;
}

.pswp__button--arrow:disabled {
  cursor: default;
  display: none;
}

.pswp__button--arrow .pswp__icn {
  width: 60px;
  height: 60px;
  background: none;
  border-radius: 0;
  margin-top: -30px;
  top: 50%;
}

.pswp--one-slide .pswp__button--arrow {
  display: none;
}

.pswp--touch .pswp__button--arrow {
  visibility: hidden;
}

.pswp--has_mouse .pswp__button--arrow {
  visibility: visible;
}

.pswp__button--arrow--prev {
  left: 0;
  right: auto;
}

.pswp__button--arrow--next {
  right: 0;
}

.pswp__button--arrow--next .pswp__icn {
  left: auto;
  right: 14px;
  transform: scale(-1, 1);
}

.pswp__button--zoom {
  display: none;
}

.pswp--zoom-allowed .pswp__button--zoom {
  display: block;
}

.pswp--zoomed-in .pswp__zoom-icn-bar-v {
  display: none;
}

.pswp__preloader {
  width: 50px;
  height: 60px;
  margin-right: auto;
  position: relative;
  overflow: hidden;
}

.pswp__preloader .pswp__icn {
  opacity: 0;
  transition: opacity .2s linear;
  animation: .6s linear infinite pswp-clockwise;
}

.pswp__preloader--active .pswp__icn {
  opacity: .85;
}

@keyframes pswp-clockwise {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.pswp__counter {
  height: 30px;
  margin-top: 15px;
  color: var(--pswp-icon-color);
  text-shadow: 1px 1px 3px var(--pswp-icon-color-secondary);
  opacity: .85;
  margin-inline-start: 20px;
  font-size: 14px;
  line-height: 30px;
}

.pswp--one-slide .pswp__counter {
  display: none;
}

.gallery {
  max-width: 100%;
  column-count: auto;
  text-align: center;
  margin: auto;
  line-height: 0;
}

.gallery picture {
  display: contents;
}

.gallery img {
  width: var(--gallery-picture-width, 8rem);
  aspect-ratio: 3 / 4;
  object-fit: cover;
  border: .1rem solid #fff;
  border-radius: 1rem;
  margin-bottom: .5rem;
  margin-left: .25rem;
  margin-right: .25rem;
}

.gallery img:hover {
  outline: solid #621708;
}

:root {
  --gallery-picture-width: 9rem;
}

@media (min-width: 768px) {
  :root {
    --gallery-picture-width: 12rem;
  }
}

body {
  min-height: 100vh;
  min-width: 250px;
  max-width: 75rem;
  background-color: var(--dark-lava);
  margin: auto;
  font-family: Open Sans, sans-serif;
  box-shadow: 0 5px 10px #00000080;
}

main {
  background-color: var(--almond);
  min-height: 50vh;
  padding: 1rem;
  overflow: auto;
}

main section {
  background-color: var(--burlywood);
  border-radius: 1rem;
  margin-bottom: 3rem;
}

main section h1 {
  background-color: var(--brown-sugar);
  color: #fff;
  border-bottom: .1rem solid #fff;
  border-radius: 1rem 1rem 0 0;
  padding: 1rem;
  font-family: Caveat, cursive;
  position: sticky;
  top: 0;
}

.icon {
  width: 1em;
  height: 1em;
  vertical-align: -.125em;
}

/*# sourceMappingURL=index.1ab1fb3e.css.map */
